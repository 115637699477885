<template>
  <div class="page page--center">
    <div v-if="!isLoading" class="page__container">
      <img class="page__container__logo" src="@/assets/svg/logo-99.svg" alt="Logo 99 Empresas">

      <div class="box__image">
        <img src="@/components/companyRegistration/tips/img/foto.png" alt="" class="box__image__photo">
        <img src="@/components/companyRegistration/tips/img/icone1.png" alt="" class="box__image__icon">
      </div>

      <div class="page__container__text">
        <div v-if="isSuccess">
          <h1 class="page__container__title">
            <span>Cadastro confirmado!</span><br>
            Já já você estará apto a utilizar a plataforma 99Empresas.
          </h1>
          <p><strong>Estamos analizando sua solicitação e em até 2 dias úteis nós entraremos em contato</strong> por email com as instruções de ativação para acessar a plataforma.</p>
          <p>Obrigado e nos falamos em breve! Para qualquer dúvida, entre em contato com <a href="mailto:simplifica@99empresas.com" title="E-mail do canal 99 Simplifica">simplifica@99empresas.com</a>.</p>
        </div>

        <div v-if="isFailed">
          <h1 v-if="expiredToken" class="page__container__title">
            Link de verificação expirado
          </h1>

          <h1 v-else-if="prospectAlreadyConfirmed" class="page__container__title">
            <span>Seu cadastro já foi confirmado!</span><br>
            Já já você estará apto a utilizar a plataforma 99Empresas.
          </h1>

          <h1 v-else class="page__container__title">
            Ocorreu um erro.
            <br>Tente novamente mais tarde.
            <br>Não foi possível confirmar seu cadastro. Para qualquer dúvida, entre em contato com <a href="mailto:simplifica@99empresas.com" title="E-mail do canal 99 Simplifica">simplifica@99empresas.com</a>.
          </h1>
        </div>

        <div class="mt-9">
          <v-btn
            href="https://99app.com/empresas"
            title="Voltar para a página do 99Empresas"
            color="warning--gradient"
            depressed
            rounded
            large
          >
            Voltar para a página do 99Empresas
          </v-btn>
        </div>
      </div>
    </div>

    <transition name="fade">
      <v-progress-linear
        v-if="isLoading"
        color="primary"
        class="loading"
        indeterminate
      />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isObject, isArray } from 'utils/functions'
import { STATUS_IS_LOADING, STATUS_SUCCESS, STATUS_FAILED } from 'utils/constants'
import persistProspect from 'mixins/persistProspect'

export default {
  name: 'ConfirmedRegistration',
  mixins: [persistProspect],
  metaInfo () {
    return {
      titleTemplate: this.routeTitle + ' | %s'
    }
  },
  computed: {
    ...mapState({
      prospect: ({ prospect }) => prospect.data
    }),

    routeTitle () {
      return this.$route.meta.title
    },

    pid () {
      return this.$route.params.pid
    },

    isLoading () {
      return this.currentStatus === STATUS_IS_LOADING
    },

    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },

    isFailed () {
      return this.currentStatus === STATUS_FAILED
    }
  },
  data () {
    return {
      currentStatus: STATUS_IS_LOADING,
      expiredToken: false,
      prospectAlreadyConfirmed: false
    }
  },
  methods: {
    sendGTMDataLayer () {
      const { id = null, paymentMethod } = this.prospect
      const getPaymentMethodName = (paymentMethod) => {
        const methods = {
          billet: 'boleto',
          creditCard: 'cartão de crédito'
        }

        return methods[paymentMethod] || 'noinfo'
      }

      this.$GTMDataLayer.send({
        event: 'cadastro-step5',
        'step-fom': '5-confirmacao-do-cadastro',
        'id-cadastro-hubspot': id || this.pid,
        pagamento: getPaymentMethodName(paymentMethod)
      })
    },

    clearUrlParam () {
      setTimeout(() => {
        this.$router.push({ name: 'ConfirmedRegistration' }).catch(() => {})
      }, 100)
    },

    isValidObject (val) {
      return isObject(val)
    },

    isValidArray (val) {
      return isArray(val)
    },

    isInvalidRequestResponse (res) {
      return !this.isValidObject(res) || (this.isValidObject(res) && !res.valid)
    },

    checkIfExpiredToken (error) {
      return /Prospect\s+has\s+expired!/i.test(error)
    },

    checkIfProspectAlreadyConfirmed (error) {
      return /Prospect\s+already\s+confirmed/i.test(error)
    },

    captureErrorMessage (res) {
      return res && this.isValidArray(res.reasons) ? res.reasons[0] : ''
    },

    async enableCompany () {
      this.currentStatus = STATUS_IS_LOADING

      try {
        const { data: res } = await this.$apiService.step7.enableCompany(this.pid)

        if (this.isInvalidRequestResponse(res)) {
          const error = this.captureErrorMessage(res)
          throw new Error(error)
        }

        this.clearUrlParam()
        this.removeProspect()
        this.currentStatus = STATUS_SUCCESS
      } catch (error) {
        this.expiredToken = this.checkIfExpiredToken(error)
        this.prospectAlreadyConfirmed = this.checkIfProspectAlreadyConfirmed(error)
        this.removeProspect()
        this.currentStatus = STATUS_FAILED
        return error
      }
    }
  },
  mounted () {
    this.sendGTMDataLayer()
    this.enableCompany()
  }
}
</script>

<style lang="scss" scoped>
.page__container {
  position: relative;
}

.page__container__text {

  @media (min-width: 600px) {
    padding-right: 100px;
  }
}

.loading {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

.box__image {
  background: $warning-2;
  border-radius: 40px;
  display: none;
  padding: 30px 50px 30px 30px;
  position: absolute;
  right: -80px;
  top: 120px;
  z-index: 1;

  &:before {
    background: url('~@/components/userRegistration/tips/img/ellipse.png') no-repeat;
    background-size: cover;
    content: '';
    display: block;
    height: 186px;
    left: 70px;
    position: absolute;
    top: 15px;
    width: 126px;
    z-index: 0;
  }

  @media (min-width: 1200px) {
    display: inline-block;
  }
}

.box__image__photo {
  max-width: 160px;
  position: relative;
  z-index: 1;
}

.box__image__icon {
  height: 55px;
  left: 166px;
  position: absolute;
  top: 120px;
  z-index: 1;
}
</style>
